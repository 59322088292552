import line from "../../assets/media/line.svg";
import backgroundImg from "../../assets/media/smallBackground.svg";
import Icon from "../../customElements/icon/icon";
import { PortfoliosBlockType } from "../Portfolio/Portfolio";
import styles from "./Portfolio.module.scss";
import cn from "classnames";
import useWidth from "hooks/useWidth";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Portfolios = () => {
  const [_, isMobile] = useWidth();
  const { slug } = useParams();

  const [project, setProject] = useState<PortfoliosBlockType>({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    fetch("https://backend.armara.dev/api/projects/" + slug)
      .then((response) => response.json())
      .then((data) => {
        setProject(data.data);
      });
  }, [slug]);

  return (
    <section
      className={`${cn(
        styles.main,
        "contentPositioner contentPositionerVertical"
      )}`}
      data-scroll-section="/"
      id="/"
    >
      <div
        className={`contentPositionerTop ${styles.main_content} d-flex flex-column gap-3`}
      >
        <div className="contentPositionerVertical">
          <div className={`mb-5 ${styles.main_picture} position-relative`}>
            <Icon img={backgroundImg} width="100%" height="100%" />
            <Icon img={project?.image || ""} width={"100%"} height="auto" />
          </div>

          {(project?.name?.length || 0) > 0 && (
            <ul className="my-3 d-flex flex-column gap-3">
              {(project.challenge?.length || 0) > 0 && (
                <div className={styles.main_content_from_api}>
                  <li>
                    <div className="d-flex gap-3">
                      <Icon img={line} width="16px" height="8px" />
                      <div
                        className={`${styles.description} d-flex flex-column gap-2`}
                      >
                        <span>Challenge</span>

                        <div
                          className={styles.main_content_from_api}
                          dangerouslySetInnerHTML={{
                            __html: project.challenge || "",
                          }}
                        />
                      </div>
                    </div>
                  </li>
                  <hr />
                  <li>
                    <div className="d-flex gap-3">
                      <Icon img={line} width="16px" height="8px" />
                      <div
                        className={`${styles.description} d-flex flex-column gap-2`}
                      >
                        <span>Solution</span>

                        <div
                          className={styles.main_content_from_api}
                          dangerouslySetInnerHTML={{
                            __html: project.solution || "",
                          }}
                        />
                      </div>
                    </div>
                  </li>
                  <hr />
                  <li>
                    <div className="d-flex gap-3">
                      <Icon img={line} width="16px" height="8px" />
                      <div
                        className={`${styles.description} d-flex flex-column gap-2`}
                      >
                        <span>Result</span>

                        <div
                          className={styles.main_content_from_api}
                          dangerouslySetInnerHTML={{
                            __html: project.result || "",
                          }}
                        />
                      </div>
                    </div>
                  </li>
                </div>
              )}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default Portfolios;
